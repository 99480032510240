.ribbon-main-container {
	display: flex;
	// background: linear-gradient(to right, #020024, #a855f7, #ec4899);
	background: linear-gradient(to right, #191924, #020024, #6366f1, #a855f7);
	// background: linear-gradient(to right, #020024, #6366f1, #a855f7, #ec4899);
	// background: rgb(2, 0, 36);
	// background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 35%, rgba(255, 0, 0, 1) 100%);
	padding: 0.5rem 2rem;
	border: none;
	color: white;
	justify-content: space-between;
	align-items: center;
	font-size: 0.8rem;

	.left-section {
		// flex: 1;
		display: flex;
		justify-content: flex-start;

		.contact-links {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 1rem;

			span {
				font-weight: 200;
				cursor: pointer;
			}

			span:hover {
				font-weight: 600;
				transition: all ease 0.1s;
			}
		}
	}

	.right-section {
		flex: 1;
		display: flex;
		justify-content: flex-end;
		gap: 2rem;

		.contact-links {
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}
	}
}

@media (width<600px) {
	.ribbon-main-container {
		padding: 0.5rem 1rem;
	}
}
