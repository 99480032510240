.footer {
	display: flex;
	flex-direction: column;
	background: #1f002d;
	// padding: 30px 0px;
	padding-top: 30px;
	font-family: "Play", sans-serif;
	text-align: center;

	.row {
		display: flex;
		justify-content: center;
		width: 100%;
		margin: 1% 0%;
		padding: 0.6% 0%;
		color: gray;
		font-size: 0.8em;

		img {
			width: 20%;
		}

		a {
			text-decoration: none;
			color: gray;
			transition: 0.5s;

			&:hover {
				color: #fff;
			}
		}

		ul {
			width: 100%;

			li {
				display: inline-block;
				margin: 0px 30px;

				a {
					i {
						font-size: 2em;
						margin: 0% 1%;
					}
				}
			}
		}

		.contact-group-container {
			display: flex;
			justify-content: space-around;
			width: 70%;
			gap: 1rem;

			.contact-container {
				width: 200px;
				display: flex;
				flex-direction: column;
				// border: 1px solid yellow;

				.designation {
					display: flex;
					justify-content: center;
					flex: 2;
					align-items: center;
				}
				.provider-name {
					flex: 1;
					color: #ee2e24;
					font-size: 0.9rem;
					cursor: pointer;
				}

				.provider-contacts {
					flex: 1;
					color: white;
					font-size: 0.9rem;
					opacity: 0.7;
					cursor: pointer;
				}
			}

			.scroll {
				width: 200px;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}
	}

	.row-copyright {
		background-color: #1b1b1b;
		color: white;
		font-size: 15px;
		padding-top: 10px;
		padding-bottom: 10px;
		font-weight: 600;

		a {
			color: white;
			text-decoration: none;

			b {
				margin-right: 5px;
			}
		}

		span {
			margin-right: 5px;
		}
	}
	@media (max-width: 720px) {
		text-align: left;
		// padding: 5%;
		padding-top: 5%;

		//    padding-left: 5%;

		.row {
			ul {
				li {
					display: block;
					margin: 10px 0px;
					text-align: left;
					padding-left: 5%;
					a {
						i {
							margin: 0% 3%;
						}
					}
				}
			}

			img {
				padding-left: 5%;
				width: 300px;
			}
		}

		.row-copyright {
			text-align: center;
		}
	}

	@media (width<520px) {
		.row {
			img {
				width: 320px;
			}
		}

		.row-copyright {
			font-size: 16px;
		}
	}
}

@media (width<1448px) {
	.footer {
		.row {
			.contact-group-container {
				width: 80%;
			}
		}
	}
}

@media (width<1268px) {
	.footer {
		.row {
			.contact-group-container {
				width: 90%;
			}
		}
	}
}

@media (width<1132px) {
	.footer {
		.row {
			.contact-group-container {
				width: 100%;
				padding-left: 10px;
				// margin-right: auto;
			}
		}
	}
}

@media (width<1034px) {
	.footer {
		.row {
			.contact-group-container {
				// width: 80%;
				width: 70%;
				// padding-left: 10px;
				// margin-right: auto;
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;
				align-items: center;
				text-align: justify;
			}
		}
	}
}

@media (width<940px) {
	.footer {
		.row {
			.contact-group-container {
				width: 544px;
				column-gap: 8rem;
			}
		}
	}
}

@media (width<723px) {
	.footer {
		.row {
			ul {
				width: 581px;
			}
		}
	}
}

@media (width<580px) {
	.footer {
		.row {
			.contact-group-container {
				width: 490px;
				column-gap: 5rem;
			}
		}
	}
}
@media (width<535px) {
	.footer {
		.row {
			.contact-group-container {
				width: 250px;

				text-align: center;
			}

			ul {
				li {
					text-align: center;
					margin-left: -30px;
				}
			}
		}
	}

	.scroll {
		// 	align-items: center;
		// 	margin-left: 184px;
		margin-top: -56px;
	}
}

.sr-only {
	width: 0.5px;
	height: 1px;
	position: absolute;
	overflow: hidden;
}

.scroll {
	--transition-time: 0.4s;
	--width-arrow-line: 6px;
	--color-arrow: #ffffff;
	display: flex;
	flex-direction: column-reverse;
	padding-bottom: 20px;
	position: relative; /* Enable relative positioning */
	left: 100px; /* Adjust this value to move the container to the right */
}

.top-btn {
	display: block;
	width: 50px;
	aspect-ratio: 1 / 1;
	border-radius: 50%;
	position: relative;
	background-color: #45124f;
	box-shadow: 0 0 5px #740ef2;
	overflow: hidden;
}

.top-btn::before,
.top-btn::after {
	content: "";
	position: absolute;
	top: 25%;
	left: 50%;
	translate: -50% 0;
}

.top-btn::before {
	width: 24px;
	aspect-ratio: 1 / 1;
	border-top: var(--width-arrow-line) solid var(--color-arrow);
	border-left: var(--width-arrow-line) solid var(--color-arrow);
	rotate: 45deg;
}

.top-btn::after {
	width: var(--width-arrow-line);
	height: 50%;
	background-color: var(--color-arrow);
}

.scroll--active .top-btn:hover::before,
.scroll--active .top-btn:hover::after {
	animation: top 0.8s infinite;
}

@keyframes top {
	0% {
		top: 100%;
	}

	100% {
		top: -50%;
	}
}

@media (width<1133px) and (width>=1034px) {
	.footer .row {
		width: 90%;
	}
}
