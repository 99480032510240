@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");

/* .call-to-action {
	background-color: #1f002d;
	width: 100%;
	padding-left: 3rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
} */

.advantage {
	z-index: 1;
	display: inline-flex;
	background-color: #1f002d;
	width: 100vw;
	margin: 0;
	box-sizing: border-box;

	padding-left: 3rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.advantage .call-header {
	background: linear-gradient(to right, #a223d1, #4631ce);
	background-clip: text;
	-webkit-background-clip: text;
	font-weight: bold;
	letter-spacing: 0.15rem;
	text-fill-color: transparent;
	text-align: center;
	font-size: 3rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.advantage .call-header .webkit-fill {
	-webkit-text-fill-color: transparent;
}

.advantage .item-1 {
	display: flex;
}

.call-to-action .call-text {
	font-size: 1.3rem;
	/* width: 90%; */
	text-align: center;
	color: #bdbdbd;
	font-weight: bold;
}

.water-wave-container {
	position: relative;
	font-family: "Poppins", sans-serif;
}

.water-wave-container h2 {
	color: #fff;
	font-size: 4rem;
	position: absolute;
	left: 85px;
	top: -27px;
	letter-spacing: 1.5px !important;
	z-index: 3;
	transform: translate(-50%, -50%);
}

.water-wave-container h2:nth-child(1) {
	color: transparent;
	z-index: 3;
	-webkit-text-stroke: 2px #9c24d0;
}

.water-wave-container h2:nth-child(2) {
	color: #9c24d0;
	animation: animation 4s ease-in-out infinite;
	z-index: 3;
}

.mobile-risk {
	display: none;
}

@media screen and (max-width: 1100px) {
	.advantage .call-header,
	.advantage .webkit-fill {
		font-size: 2rem;
	}
	.water-wave-container h2 {
		font-size: 2rem;
		/* position: absolute !important; */
		left: 50px;
		top: -5px;
		transform: translate(-50%, -50%);
	}
}

@media screen and (max-width: 800px) {
	.advantage .call-header,
	.advantage .webkit-fill {
		font-size: 2rem;
	}
	.water-wave-container h2 {
		font-size: 2rem;
		/* position: absolute !important; */
		left: 40px;
		top: -5px;
		transform: translate(-50%, -50%);
	}

	.advantage {
		padding-left: 1rem;
	}
}

@media screen and (max-width: 706px) {
	.advantage .call-header {
		gap: 20px;
		flex-direction: column;
		padding: 0rem 0rem;
		align-items: center;
		justify-content: center;
	}

	.advantage .partner-bttn {
		margin-right: 0px !important;
	}

	.advantage .webkit-fill h2 {
		font-size: 1.2rem;
		display: block;
	}

	.advantage .item-1 {
		flex-direction: row;
	}

	.advantage {
		padding: 1rem 0rem;
	}

	.water-wave {
		display: none;
	}

	.mobile-risk {
		display: contents;
	}
}

@media screen and (max-width: 1000px) {
	.advantage .call-header,
	.advantage .webkit-fill,
	.advantage .water-wave-container h2 {
		font-size: 1.5rem;
	}
}

@keyframes animation {
	0%,
	100% {
		clip-path: polygon(0% 45%, 16% 44%, 33% 50%, 54% 60%, 70% 61%, 84% 59%, 100% 52%, 100% 100%, 0% 100%);
	}

	50% {
		clip-path: polygon(0% 60%, 15% 65%, 34% 66%, 51% 62%, 67% 50%, 84% 45%, 100% 46%, 100% 100%, 0% 100%);
	}
}
