.text-gradient {
	color: transparent;
	background-clip: text;
	background-image: linear-gradient(to right, #6366f1, #a855f7, #ec4899);
}

.gradient-text {
	background: linear-gradient(to right, #ff7e5f, #feb47b);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
