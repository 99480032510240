.knowMore button {
	background-color: #25a18e;
	color: white;
	border: none;
	border-radius: 15px;
	padding: 8px 14px;
	cursor: pointer;
	margin-top: 1rem;
}
.knowMore button:hover {
	background-color: #c9f5ee;
	color: #25a18e;
	transition-duration: 0.6s;
}

.know-more-popup {
	background-color: rgba(0, 0, 0, 0.68);
	position: absolute;
	color: white;
	font-weight: bold;
	padding: 2rem;
	border-radius: 10px;
	/* width: 800px; */
	z-index: 99;
	/* left: 30%; */
	top: 32%;
	font-size: 1rem;
	/* display: none; */
}

.cross-button {
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;
	padding: 2rem;
}

.main-para,
.second-text,
.second-para {
	margin-top: 2rem;
}
