@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	border-color: var(--border);
	scroll-behavior: smooth;
}

button {
	outline: none;
}

.heading {
	color: #f2f3f4;
	font-weight: bold;
	font-size: 3rem !important;
	text-align: center;
}

@media (min-width: 768px) {
	.heading {
		font-size: 3rem; /* 5xl */
	}
}
